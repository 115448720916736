import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
// 引入公共方法扩展
import common from './common.js'
Vue.prototype.common = common
import  VueWechatTitle from 'vue-wechat-title'
import * as echarts from 'echarts'
import * as math from 'mathjs'
import './assets/iconfont/iconfont.css';
// import 'default-passive-events'
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueWechatTitle)
Vue.prototype.$ajax = axios
Vue.prototype.$math = math
Vue.config.productionTip = false

Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});


router.beforeEach((to, from, next) => {
    // next();
    if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
        if (localStorage.getItem('JWT_TOKEN')) { // 通过vuex state获取当前的token是否存在
            router.app.$ajax({
                    method: 'get',
                    url: store.state.server_url + '/admin/login/checkToken',
                })
                .then(function(res) {
                    next();
                })
                .catch(function(err) {})
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
    } else {
        next();
    }
})
router.afterEach((to, from, next) => {
    // console.log(to, from)
})



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')