import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        redirect: '/home',
        component: HomeView,
        meta: { title: '资源概况', requireAuth: true, keepAlive: true },
        children: [{
            path: '/home',
            component: () => import('../views/home/HomeIndex.vue'),
            // redirect: '/manager/list',
            meta: { title: '资源概况', name: 'home', requireAuth: true, keepAlive: true },
        }, {
            path: '/query',
            component: () => import('../views/query/QueryIndex.vue'),
            // redirect: '/manager/list',
            meta: { title: '综合查询', name: 'query', requireAuth: true },
        }, {
            path: '/achievement',
            component: () => import('../views/achievement/AchievementIndex.vue'),
            // redirect: '/manager/list',
            meta: { title: '试点成果', name: 'achievement', requireAuth: true },
        }, {
            path: '/uplog',
            component: () => import('../views/uplog/UplogIndex.vue'),
            // redirect: '/manager/list',
            meta: { title: '更新日志', name: 'uplog', requireAuth: true },
        }, {
            path: '/map',
            component: () => import('../views/map/MapIndex.vue'),
            // redirect: '/manager/list',
            meta: { title: '资源云图', name: 'map', requireAuth: true },
        }, {
            path: '/maptest',
            component: () => import('../views/map/MapTest.vue'),
            // redirect: '/manager/list',
            meta: { title: '地图测试', name: 'maptest', requireAuth: true },
        }, {
            path: '/setting',
            component: () => import('../views/setting/SettingIndex.vue'),
            redirect: '/setting/users/list',
            children: [{
                path: '/setting/users/list',
                name: 'userslist',
                meta: { title: '用户列表', name: 'setting', requireAuth: true },
                component: () => import('../views/setting/users/UsersList.vue')
            }, {
                path: '/setting/users/add',
                name: 'usersadd',
                meta: {
                    title: '添加用户',
                    name: 'setting',
                    requireAuth: true
                },
                component: () => import('../views/setting/users/UsersAdd.vue')
            }]
        }],
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录', name: 'login' },
        component: () => import('../views/LoginView.vue')
    }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router