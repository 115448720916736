import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        soft_name: '森林资源数据通',
        soft_version: 'V1.0.9',
        company_name: 'dingguodong.net',
        copyright_info: '2024',
        server_url: 'http://mini.dingguodong.net',
        mids:'',
        user_info: {
            manager_name: '',
            manager_power: '',
            manager_id: '',
            company_id: ''
        },
        menuIsCollapse: true,
        dutiesOptions: [{
            value: '0',
            label: '董事长'
        }, {
            value: '1',
            label: '总经理'
        }, {
            value: '2',
            label: '副总经理'
        }, {
            value: '3',
            label: '财务总监'
        }, {
            value: '4',
            label: '秘书长'
        }, {
            value: '5',
            label: '部长'
        }, {
            value: '6',
            label: '副部长'
        }, {
            value: '7',
            label: '主管'
        }, {
            value: '8',
            label: '科员'
        }],
        companyOptions: [{
            value: '0',
            label: '集团'
        }, {
            value: '1',
            label: '汪清林业局'
        }, {
            value: '2',
            label: '白河林业局'
        }, {
            value: '3',
            label: '黄泥河林业局'
        }, {
            value: '4',
            label: '敦化林业局'
        }, {
            value: '5',
            label: '大石头林业局'
        }, {
            value: '6',
            label: '八家子林业局'
        }, {
            value: '7',
            label: '和龙林业局'
        }, {
            value: '8',
            label: '天桥岭林业局'
        }, {
            value: '9',
            label: '大兴沟林业局'
        }, {
            value: '10',
            label: '珲春林业局'
        }, {
            value: '11',
            label: '安图森林经营局'
        }, {
            value: '12',
            label: '白石山林业局'
        }, {
            value: '13',
            label: '红石林业局'
        }, {
            value: '14',
            label: '露水河林业局'
        }, {
            value: '15',
            label: '泉阳林业局'
        }, {
            value: '16',
            label: '松江河林业局'
        }, {
            value: '17',
            label: '湾沟林业局'
        }, {
            value: '18',
            label: '三岔子林业局'
        }, {
            value: '19',
            label: '临江林业局'
        }],
        companysOptions: [{
                "value": "全集团",
                "label": "全集团"
            },
            {
                "value": "吉林森工",
                "label": "吉林森工",
                "children": [{
                        "value": "三岔子林业局",
                        "label": "三岔子林业局",
                        "children": [{
                                "value": "三岔子林场",
                                "label": "三岔子林场"
                            },
                            {
                                "value": "三道湖林场",
                                "label": "三道湖林场"
                            },
                            {
                                "value": "新胜林场",
                                "label": "新胜林场"
                            },
                            {
                                "value": "景山林场",
                                "label": "景山林场"
                            },
                            {
                                "value": "白江河林场",
                                "label": "白江河林场"
                            },
                            {
                                "value": "那儿轰林场",
                                "label": "那儿轰林场"
                            },
                            {
                                "value": "龙湾林场",
                                "label": "龙湾林场"
                            }
                        ]
                    },
                    {
                        "value": "临江林业局",
                        "label": "临江林业局",
                        "children": [{
                                "value": "东小山林场",
                                "label": "东小山林场"
                            },
                            {
                                "value": "大西林场",
                                "label": "大西林场"
                            },
                            {
                                "value": "柳树河林场",
                                "label": "柳树河林场"
                            },
                            {
                                "value": "柳毛河林场",
                                "label": "柳毛河林场"
                            },
                            {
                                "value": "桦树林场",
                                "label": "桦树林场"
                            },
                            {
                                "value": "红土山经营所",
                                "label": "红土山经营所"
                            },
                            {
                                "value": "芒河经营所",
                                "label": "芒河经营所"
                            },
                            {
                                "value": "西小山林场",
                                "label": "西小山林场"
                            },
                            {
                                "value": "贾家营林场",
                                "label": "贾家营林场"
                            },
                            {
                                "value": "金山林场",
                                "label": "金山林场"
                            },
                            {
                                "value": "银山经营所",
                                "label": "银山经营所"
                            },
                            {
                                "value": "闹枝林场",
                                "label": "闹枝林场"
                            }
                        ]
                    },
                    {
                        "value": "松江河林业局",
                        "label": "松江河林业局",
                        "children": [{
                                "value": "前川林场",
                                "label": "前川林场"
                            },
                            {
                                "value": "开峰林场",
                                "label": "开峰林场"
                            },
                            {
                                "value": "抚南林场",
                                "label": "抚南林场"
                            },
                            {
                                "value": "曙光林场",
                                "label": "曙光林场"
                            },
                            {
                                "value": "松山林场",
                                "label": "松山林场"
                            },
                            {
                                "value": "板石河林场",
                                "label": "板石河林场"
                            },
                            {
                                "value": "槽子河林场",
                                "label": "槽子河林场"
                            },
                            {
                                "value": "漫江林场",
                                "label": "漫江林场"
                            },
                            {
                                "value": "白西林场",
                                "label": "白西林场"
                            },
                            {
                                "value": "老岭林场",
                                "label": "老岭林场"
                            },
                            {
                                "value": "胜利林场",
                                "label": "胜利林场"
                            },
                            {
                                "value": "锦北林场",
                                "label": "锦北林场"
                            },
                            {
                                "value": "青川林场",
                                "label": "青川林场"
                            },
                            {
                                "value": "马鞍山林场",
                                "label": "马鞍山林场"
                            },
                            {
                                "value": "黑河林场",
                                "label": "黑河林场"
                            }
                        ]
                    },
                    {
                        "value": "泉阳林业局",
                        "label": "泉阳林业局",
                        "children": [{
                                "value": "东北岔林场",
                                "label": "东北岔林场"
                            },
                            {
                                "value": "兴参林场",
                                "label": "兴参林场"
                            },
                            {
                                "value": "北岗林场",
                                "label": "北岗林场"
                            },
                            {
                                "value": "大东林场",
                                "label": "大东林场"
                            },
                            {
                                "value": "大川林场",
                                "label": "大川林场"
                            },
                            {
                                "value": "大顶子林场",
                                "label": "大顶子林场"
                            },
                            {
                                "value": "抚安林场",
                                "label": "抚安林场"
                            },
                            {
                                "value": "明水林场",
                                "label": "明水林场"
                            },
                            {
                                "value": "榆树林场",
                                "label": "榆树林场"
                            },
                            {
                                "value": "泉水林场",
                                "label": "泉水林场"
                            },
                            {
                                "value": "胜利林场",
                                "label": "胜利林场"
                            }
                        ]
                    },
                    {
                        "value": "湾沟林业局",
                        "label": "湾沟林业局",
                        "children": [{
                                "value": "三道花园",
                                "label": "三道花园"
                            },
                            {
                                "value": "二道花园",
                                "label": "二道花园"
                            },
                            {
                                "value": "仙人洞林场",
                                "label": "仙人洞林场"
                            },
                            {
                                "value": "四平林场",
                                "label": "四平林场"
                            },
                            {
                                "value": "大安林场",
                                "label": "大安林场"
                            },
                            {
                                "value": "大营林场",
                                "label": "大营林场"
                            },
                            {
                                "value": "夹皮沟林场",
                                "label": "夹皮沟林场"
                            },
                            {
                                "value": "松树林场",
                                "label": "松树林场"
                            },
                            {
                                "value": "湾沟林场",
                                "label": "湾沟林场"
                            }
                        ]
                    },
                    {
                        "value": "白石山林业局",
                        "label": "白石山林业局",
                        "children": [{
                                "value": "双山林场",
                                "label": "双山林场"
                            },
                            {
                                "value": "大石河林场",
                                "label": "大石河林场"
                            },
                            {
                                "value": "大趟子林场",
                                "label": "大趟子林场"
                            },
                            {
                                "value": "漂河林场",
                                "label": "漂河林场"
                            },
                            {
                                "value": "琵河林场",
                                "label": "琵河林场"
                            },
                            {
                                "value": "白石山林场",
                                "label": "白石山林场"
                            },
                            {
                                "value": "胜利河林场",
                                "label": "胜利河林场"
                            },
                            {
                                "value": "黄松甸林场",
                                "label": "黄松甸林场"
                            }
                        ]
                    },
                    {
                        "value": "红石林业局",
                        "label": "红石林业局",
                        "children": [{
                                "value": "东兴林场",
                                "label": "东兴林场"
                            },
                            {
                                "value": "二道沟林场",
                                "label": "二道沟林场"
                            },
                            {
                                "value": "帽山林场",
                                "label": "帽山林场"
                            },
                            {
                                "value": "批洲林场",
                                "label": "批洲林场"
                            },
                            {
                                "value": "板庙子林场",
                                "label": "板庙子林场"
                            },
                            {
                                "value": "桦树经营所",
                                "label": "桦树经营所"
                            },
                            {
                                "value": "梨树经营所",
                                "label": "梨树经营所"
                            },
                            {
                                "value": "红石林场",
                                "label": "红石林场"
                            },
                            {
                                "value": "老岭经营所",
                                "label": "老岭经营所"
                            },
                            {
                                "value": "黄泥河林场",
                                "label": "黄泥河林场"
                            }
                        ]
                    },
                    {
                        "value": "露水河林业局",
                        "label": "露水河林业局",
                        "children": [{
                                "value": "东升林场",
                                "label": "东升林场"
                            },
                            {
                                "value": "四湖林场",
                                "label": "四湖林场"
                            },
                            {
                                "value": "新兴林场",
                                "label": "新兴林场"
                            },
                            {
                                "value": "永青林场",
                                "label": "永青林场"
                            },
                            {
                                "value": "清水河林场",
                                "label": "清水河林场"
                            },
                            {
                                "value": "红光林场",
                                "label": "红光林场"
                            },
                            {
                                "value": "西林河林场",
                                "label": "西林河林场"
                            },
                            {
                                "value": "黎明林场",
                                "label": "黎明林场"
                            }
                        ]
                    }
                ]
            }
        ],
        QueryOptions: [{
                "value": "全集团",
                "label": "全集团",
            },
            {
                "value": "吉林森工",
                "label": "吉林森工",
                "children": [{
                    "value": "三岔子林业局",
                    "label": "三岔子林业局"
                }, {
                    "value": "临江林业局",
                    "label": "临江林业局"
                }, {
                    "value": "松江河林业局",
                    "label": "松江河林业局"
                }, {
                    "value": "泉阳林业局",
                    "label": "泉阳林业局"
                }, {
                    "value": "湾沟林业局",
                    "label": "湾沟林业局"
                }, {
                    "value": "白石山林业局",
                    "label": "白石山林业局"
                }, {
                    "value": "红石林业局",
                    "label": "红石林业局"
                }, {
                    "value": "露水河林业局",
                    "label": "露水河林业局"
                }]
            },
            // {
            //     "value": "长白山森工",
            //     "label": "长白山森工",
            //     "children": [{
            //         "value": "八家子林业局",
            //         "label": "八家子林业局"
            //     }, {
            //         "value": "和龙林业局",
            //         "label": "和龙林业局"
            //     }, {
            //         "value": "大兴沟林业局",
            //         "label": "大兴沟林业局"
            //     }, {
            //         "value": "大石头林业局",
            //         "label": "大石头林业局"
            //     }, {
            //         "value": "天桥岭林业局",
            //         "label": "天桥岭林业局"
            //     }, {
            //         "value": "安图森林经营局",
            //         "label": "安图森林经营局"
            //     }, {
            //         "value": "敦化林业局",
            //         "label": "敦化林业局"
            //     }, {
            //         "value": "汪清林业局",
            //         "label": "汪清林业局"
            //     }, {
            //         "value": "珲春林业局",
            //         "label": "珲春林业局"
            //     }, {
            //         "value": "白河林业局",
            //         "label": "白河林业局"
            //     }, {
            //         "value": "黄泥河林业局",
            //         "label": "黄泥河林业局"
            //     }]
            // }
        ],
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})